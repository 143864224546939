// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import strings from '../../../configs/strings'

export const serachParent = createAsyncThunk('parents/search', async (query) => {
  const response = await axios.post('/parent/search', { query })
  return response
})

export const createRecord = createAsyncThunk('parents/addParent', async (data) => {
  const response = await axios.post('/parent/create', {
    ...data,
    identification_id: data?.identification_id?.id,
    nationality_id: data?.nationality_id?.id,
    branch_id: data?.branch_id?.id
  })
  return response
})

export const getKidsList = createAsyncThunk('parents/getKidsList', async (data, { getState }) => {
  const parent_id = getState().parents?.parentInfo?.id
  if (!parent_id) return { data: { data: [] } }
  const response = await axios.post('/parent/kids', { parent_id })
  return response
})

export const checkInKids = createAsyncThunk('parents/kids/checkin', async ({ badge, kidsList }, { dispatch, getState }) => {
  const parent_id = getState().parents?.parentInfo?.id
  if (!parent_id) return { data: { data: [] } }
  const response = await axios.post('/parent/kids/checkin', {
    parent_id,
    badge,
    kidsList
  })
  if (response?.data?.status) {
    await dispatch(getKidsList())
  }
  return response
})

export const checkOutKids = createAsyncThunk('parents/kids/checkOutKids', async ({ kidsList }, { dispatch }) => {
  const ids = kidsList.map(({ id }) => id)
  const response = await axios.post('/parent/kids/checkout', { ids })
  await dispatch(getKidsList())
  return response
})


export const updateRecord = createAsyncThunk('parents/updateParent', async (data, { dispatch }) => {
  const response = await axios.post('/parent/update', {
    ...data,
    identification_id: data?.identification_id?.id,
    nationality_id: data?.nationality_id?.id,
    branch_id: data?.branch_id?.id
  })
  await dispatch(getKidsList())
  return response
})

export const addAKid = createAsyncThunk('parents/addKid', async (data, { dispatch, getState }) => {
  const response = await axios.post('/kid/create', {
    ...data,
    parent_id: getState().parents?.parentInfo?.id
  })
  await dispatch(getKidsList())
  return response
})

export const updateKid = createAsyncThunk('parents/updateKid', async (data, { dispatch }) => {
  const response = await axios.post('/kid/update', data)
  await dispatch(getKidsList())
  return response
})

export const deleteKid = createAsyncThunk('parents/deleteKid', async ({ id }, { dispatch }) => {
  const response = await axios.post('/kid/delete', { id })
  await dispatch(getKidsList())
  return response
})

export const getIdentification = createAsyncThunk('parents/identification/list', async () => {
  const response = await axios.post('/identification/list')
  return response
})

export const getNationality = createAsyncThunk('parents/nationality/list', async () => {
  const response = await axios.post('/nationality/list')
  return response
})

export const getBranch = createAsyncThunk('parents/branch/list', async () => {
  const response = await axios.post('/branch/list')
  return response
})


export const getAllBaseData = createAsyncThunk('parents/getAllBaseData', async (id, { dispatch }) => {
  await dispatch(getNationality())
  await dispatch(getBranch())
  await dispatch(getIdentification())
})

export const sendOTP = createAsyncThunk('parents/sendOTP', async (data, { getState }) => {
  const { id, mobile } = getState().parents.parentInfo
  const response = await axios.post('/otp/send', {
    id,
    mobile: data?.mobile || mobile
  })
  return response
})

export const verifyOTP = createAsyncThunk('parents/verifyOTP', async (data, { getState }) => {
  const { id, mobile } = getState().parents.parentInfo
  const response = await axios.post('/otp/verify', {
    id,
    mobile: data?.mobile || mobile,
    otp: data.otp
  })
  return response
})

export const getKidsSessionTime = createAsyncThunk('parents/getKidsSessionTime', async (params) => {
  const response = await axios.get(`/kids/session/time`, { params })
  return response
})

export const getParentAndverifyPayment = createAsyncThunk('parents/getParentAndverifyPayment', async (q) => {
  const response = await axios.get(`/kids/session/payments${q}`)
  return response
})

export const resumeSubscription = createAsyncThunk('parents/resumeSubscription', async (id, { dispatch }) => {
  const response = await axios.patch('/subscription/freeze', { id })
  await dispatch(getKidsList())
  return response
})

export const parentsSlice = createSlice({
  name: 'parents',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    message: null,
    loading: 'idle',
    currentRequestId: undefined,
    IDENTIFICATION: [],
    NATIONALITY: [],
    BRANCH: [],
    parentInfo: null,
    KIDS_DATA: [],
    PARENTS: [],
    selectedKid: [],
    otpSent: false,
    nextAction: null,
    tableArray: { msg: "", data: [] },
    redirectKid: null
  },
  reducers: {
    setRedirect: (state, action) => {
      state.redirectKid = action.payload
    },
    clearMessage: (state) => {
      state.message = null
    },
    setParent: (state, action) => {
      state.parentInfo = action.payload
    },
    addKidToSelection: (state, action) => {
      state.selectedKid.push(action.payload)
    },
    setKidToSelection: (state, action) => {
      state.selectedKid = action.payload
    },
    removeKidFromSelection: (state, action) => {
      state.selectedKid = state.selectedKid.filter(k => k.id !== action.payload.id)
    },
    nextAction: (state, action) => {
      state.nextAction = action.payload
    },
    setTableArray: (state, action) => {
      state.tableArray = action.payload
    },
    restParent: (state) => {
      state.tableArray = { msg: "", data: [] }
      state.nextAction = null
      state.otpSent = false
      state.selectedKid = []
      state.parentInfo = null
      state.message = null
      state.redirectKid = null
      state.KIDS_DATA = []
      state.PARENTS = []
    }
  },
  extraReducers: builder => {
    builder
      .addCase(createRecord.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
          state.currentRequestId = action.meta.requestId
        }
      })
      .addCase(createRecord.fulfilled, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.currentRequestId = undefined
          state.message = action.payload.data.message
        }
      })
      .addCase(createRecord.rejected, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.message = action?.payload?.data?.message || strings.SOMETHING_WENT_WRONG_REDUCER
          state.currentRequestId = undefined
        }
      })
      .addCase(updateRecord.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
          state.currentRequestId = action.meta.requestId
        }
      })
      .addCase(updateRecord.fulfilled, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.currentRequestId = undefined
          state.message = action.payload.data.message
        }
      })
      .addCase(updateRecord.rejected, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.message = action?.payload?.data?.message || strings.SOMETHING_WENT_WRONG_REDUCER
          state.currentRequestId = undefined
        }
      })
      .addCase(addAKid.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
          state.currentRequestId = action.meta.requestId
        }
      })
      .addCase(addAKid.fulfilled, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.currentRequestId = undefined
          state.message = action.payload.data.message
        }
      })
      .addCase(addAKid.rejected, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.message = action?.payload?.data?.message || strings.SOMETHING_WENT_WRONG_REDUCER
          state.currentRequestId = undefined
        }
      })
      .addCase(updateKid.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
          state.currentRequestId = action.meta.requestId
        }
      })
      .addCase(updateKid.fulfilled, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.currentRequestId = undefined
          state.message = action.payload.data.message
        }
      })
      .addCase(updateKid.rejected, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.message = action?.payload?.data?.message || strings.SOMETHING_WENT_WRONG_REDUCER
          state.currentRequestId = undefined
        }
      })
      .addCase(deleteKid.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
          state.currentRequestId = action.meta.requestId
        }
      })
      .addCase(deleteKid.fulfilled, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.currentRequestId = undefined
          state.message = action.payload.data.message
          state.selectedKid = []
        }
      })
      .addCase(deleteKid.rejected, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.message = action?.payload?.data?.message || strings.SOMETHING_WENT_WRONG_REDUCER
          state.currentRequestId = undefined
        }
      })
      .addCase(serachParent.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
          state.currentRequestId = action.meta.requestId
        }
      })
      .addCase(serachParent.fulfilled, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.currentRequestId = undefined
          state.PARENTS = action.payload.data.data
        }
      })
      .addCase(serachParent.rejected, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.currentRequestId = undefined
        }
      })
      .addCase(getKidsList.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
          state.currentRequestId = action.meta.requestId
        }
      })
      .addCase(getKidsList.fulfilled, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.currentRequestId = undefined
          state.message = action.payload.data.message
        }
        state.KIDS_DATA = action?.payload?.data?.data || []
        state.selectedKid = []
        state.otpSent = false
      })
      .addCase(getKidsList.rejected, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          // state.message = action?.payload?.data?.message || strings.SOMETHING_WENT_WRONG_REDUCER
          state.currentRequestId = undefined
        }
      })
      .addCase(sendOTP.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
          state.currentRequestId = action.meta.requestId
        }
      })
      .addCase(sendOTP.fulfilled, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.currentRequestId = undefined
          state.message = action.payload.data.message
          state.otpSent = action.payload?.data?.status
        }
      })
      .addCase(sendOTP.rejected, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.message = action?.payload?.data?.message || strings.SOMETHING_WENT_WRONG_REDUCER
          state.currentRequestId = undefined
        }
      })
      .addCase(verifyOTP.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
          state.currentRequestId = action.meta.requestId
        }
      })
      .addCase(verifyOTP.fulfilled, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.currentRequestId = undefined
          state.message = action?.payload?.data?.message?.status === 'approved' ? strings.OTP_VERIFICATION_SUCCESS : strings.INVALID_OTP
        }
      })
      .addCase(verifyOTP.rejected, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.message = action?.payload?.data?.message || strings.SOMETHING_WENT_WRONG_REDUCER
          state.currentRequestId = undefined
        }
      })
      .addCase(getIdentification.fulfilled, (state, action) => {
        state.IDENTIFICATION = action.payload.data.data
      })
      .addCase(getBranch.fulfilled, (state, action) => {
        state.BRANCH = action.payload.data.data
      })
      .addCase(getNationality.fulfilled, (state, action) => {
        state.NATIONALITY = action.payload.data.data
        state.message = action.payload.data.message
      })
      .addCase(getParentAndverifyPayment.fulfilled, (state, action) => {
        state.parentInfo = action.payload.data?.data?.parent
        state.bulkPmt = action.payload.data?.data
      })
  }
})

export const actions = parentsSlice.actions

export default parentsSlice.reducer
