// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const msgCount = localStorage.getItem('msgCount') || 0

export const getUserProfile = createAsyncThunk('appChat/getTasks', async () => {
  const response = await axios.get('/apps/chat/users/profile-user')
  return response.data
})

export const getChatContacts = createAsyncThunk('appChat/getChatContacts', async () => {
  const response = await axios.get('/apps/chat/chats-and-contacts')
  return response.data
})

export const selectChat = createAsyncThunk('appChat/selectChat', async (id) => {
  const response = await axios.get('/chats/user', { params: { id } })
  return response
})

export const sendMsg = createAsyncThunk('appChat/sendMsg', async (obj, { dispatch }) => {
  const response = await axios.post('/apps/chat/send-msg', { obj })
  await dispatch(selectChat(obj.contact.id))
  return response.data
})

export const sendMessage = createAsyncThunk('appChat/sendMessage', async (data, { dispatch, getState }) => {
  const { selectedUser } = getState().chat
  const response = await axios.post('/chats', data)
  await dispatch(selectChat(selectedUser.id))
  return response
})

export const getChatsData = createAsyncThunk('appChat/getChatsData', async () => {
  const response = await axios.get('/chats')
  return response
})

export const appChatSlice = createSlice({
  name: 'chat',
  initialState: {
    chats: [],
    contacts: [],
    userProfile: {},
    selectedUser: {},
    chatData: [],
    chatLoading: false,
    msgCount
  },
  reducers: {
    updateMsgCount: (state, action) => {
      state.msgCount = action.payload
    },
    chatData: (state, action) => {
      state.chatData = action.payload
    },
    sendMessage: (state, action) => {
      const msg = {
        ...action.payload,
        userId: state.selectedUser.id,
        receiverId: state.selectedUser.id,
        replyTo: null,
        status: true,
        createdAt: new Date(),
        updatedAt: new Date(),
        pending: true
      }
      state.selectedUser.chats.push(msg)
      state.chatData = state.chatData
        .map(c => {
          if (parseInt(c.id) === parseInt(state.selectedUser.id)) {
            c.chats.push(msg)
            return c
          } else {
            return c
          }
        })
    },
    newMessageResponse: (state, action) => {
      if (action.payload.data.receiverId === state.selectedUser.id) {
        const indexSelectedUser = state.selectedUser.chats.findIndex(x => x.id === action.payload.id)
        state.selectedUser.chats[indexSelectedUser] = { ...action.payload.data, pending: false }
      }
      const indexChats = state.chatData.findIndex(x => x.id === action.payload.data.receiverId)
      const chatIndex = state.chatData[indexChats].chats.findIndex(x => x.id === action.payload.id)
      state.chatData[indexChats].chats[chatIndex] = { ...action.payload.data, pending: false }
    },
    onMessageDelivered: (state, action) => {
      if (action.payload.receiverId === state.selectedUser.id) {
        const indexSelectedUser = state.selectedUser.chats.findIndex(x => x.id === action.payload.id)
        state.selectedUser.chats[indexSelectedUser].isDelivered = 1
      }
      const indexChats = state.chatData.findIndex(x => x.id === action.payload.receiverId)
      const chatIndex = state.chatData[indexChats].chats.findIndex(x => x.id === action.payload.id)
      state.chatData[indexChats].chats[chatIndex].isDelivered = 1
    },
    selectUser: (state, action) => {
      state.chatLoading = true
      state.selectedUser = state.chatData.filter(c => parseInt(c.id) === parseInt(action.payload))[0]
    },
    updateChatData: (state, action) => {
      if (state.selectedUser.id === action.payload.id) {
        state.selectedUser.chats = action.payload.data
      }
      state.chatLoading = false
      state.chatData = state.chatData
        .map(c => {
          if (parseInt(c.id) === parseInt(action.payload.id)) {
            c.chats = action.payload.data
            return c
          } else {
            return c
          }
        })
    },
    updateMessage: (state, action) => {
      if (parseInt(state.selectedUser.id) === action.payload.senderId) {
        state.selectedUser.chats.push(action.payload)
      }
      state.chatData = state.chatData
        .map(c => {
          if (parseInt(c.id) === parseInt(action.payload.senderId)) {
            c.chats.push(action.payload)
            return c
          } else {
            return c
          }
        })
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.userProfile = action.payload
      })
      .addCase(getChatContacts.fulfilled, (state, action) => {
        state.chats = action.payload.chatsContacts
        state.contacts = action.payload.contacts
      })
      .addCase(selectChat.fulfilled, (state, action) => {
        state.selectedUser = {
          ...state.chatData.filter(c => parseInt(c.id) === parseInt(action.payload.data?.params?.id))[0],
          chats: action.payload.data?.data
        }
      })
      .addCase(getChatsData.fulfilled, (state, action) => {
        state.chatData = action.payload?.data?.data || []
      })
  }
})

export const actions = appChatSlice.actions

export default appChatSlice.reducer
