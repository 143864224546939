import {
    getStorage, ref, listAll,
    getDownloadURL,
    uploadBytes
} from "firebase/storage"
import strings from "../../configs/strings"
import { randomID } from "./randomID"

/**
 * File Manager
 * @param {*} props 
 * @returns 
 */

const getAllFiles = (props, firebase) => {
    return new Promise(async (resolve, reject) => {
        try {
            const { filePath } = props
            const storage = getStorage(firebase)
            const storageRef = ref(storage, filePath)

            const results = await listAll(storageRef)
            const request = results.items.map(async itemRef => ({ url: await getDownloadURL(itemRef), fileName: itemRef.name }))

            const respone = await Promise.all(request)
            resolve(respone)
        } catch (err) {
            reject({ status: false, message: strings.ERROR, err })
        }
    })
}

/**
 * 
 * @param {*} props 
 * @returns 
 */
const downloadFiles = (props, firebase) => {
    return new Promise((resolve, reject) => {
        const { fileName, filePath } = props
        const storage = getStorage(firebase)
        const storageRef = ref(storage, filePath + fileName)

        getDownloadURL(storageRef)
            .then((url) => {
                resolve(url)
            })
            .catch(err => {
                reject({ status: false, message: strings.ERROR, err })
            })
    })
}

/**
 * 
 * @param {*} props 
 * @returns 
 */
const uploadFiles = (props, firebase) => {
    return new Promise((resolve, reject) => {
        if (!(props && props.files)) {
            reject({ status: false, message: 'No file to upload' })
        }

        const { fileName, filePath, files } = props
        const storage = getStorage(firebase)

        let file
        const request = []
        if (files.name) {
            file = files
            const _fileName = fileName ? fileName : randomID(file)
            const storageRef = ref(storage, filePath + _fileName)
            const _request = uploadBytes(storageRef, file, { contentType: file.type })
                .catch(e => console.log(e))
            request.push(_request)
        } else {
            for (let i = 0; i < files.length; i++) {
                file = files[i]
                const _fileName = files.length === 1 && fileName ? fileName : randomID(file)
                const storageRef = ref(storage, filePath + _fileName)
                const _request = uploadBytes(storageRef, file, { contentType: file.type })
                    .catch(e => console.log(e))
                request.push(_request)
            }
        }

        // const request = [...files].map(file => {
        //   const _fileName = files.length === 1 && fileName ? fileName : randomID(file)
        //   const storageRef = ref(storage, filePath + _fileName)
        //   return uploadBytes(storageRef, file, { contentType: file.type })
        //     .catch(e => console.log(e))
        // })

        Promise.all(request)
            .then(respone => {
                resolve({ status: true, message: strings.UPLOADED, respone })
            })
            .catch(err => {
                reject({ status: false, message: strings.ERROR, err })
            })
    })
}

export const fileHandler = {
    uploadFiles,
    downloadFiles,
    getAllFiles
}
