// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import strings from '../../../configs/strings'

export const getData = createAsyncThunk('rooms/getData', async params => {
  const response = await axios.post('/room/listall', params)
  return response
})

export const getAccessMapping = createAsyncThunk('rooms/getAccessMapping', async () => {
  const response = await axios.post('/roomaccess/list')
  return response
})

export const getAllRecords = createAsyncThunk('rooms/lists', async () => {
  const response = await axios.post('/room/listall')
  return response
})

export const createRecord = createAsyncThunk('rooms/create', async (data, { dispatch }) => {
  const payload = {
    ...data,
    branch_id: data?.branch_id?.id
  }
  const response = await axios.post('/room/create', payload)
  await dispatch(getData())
  return response
})

export const getBranch = createAsyncThunk('rooms/branch/list', async () => {
  const response = await axios.post('/branch/list')
  return response
})

export const getUsers = createAsyncThunk('rooms/getUsers/list', async () => {
  const response = await axios.post('/room/supervisor/list')
  return response
})

export const deleteRow = createAsyncThunk('rooms/deleteRow', async ({ id }, { dispatch }) => {
  const response = await axios.post('/room/delete', { id })
  await dispatch(getData())
  return response
})

export const editRow = createAsyncThunk('rooms/editRow', async (data, { dispatch }) => {
  const payload = {
    ...data,
    branch_id: data?.branch_id?.id
  }
  const response = await axios.post('/room/data/update', payload)
  await dispatch(getData())
  return response
})

export const revokeAccess = createAsyncThunk('rooms/revokeAccess', async (data, { dispatch }) => {
  const response = await axios.post('/room/revoke', data)
  await dispatch(getData())
  return response
})

export const datatablesSlice = createSlice({
  name: 'rooms',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    message: null,
    loading: 'idle',
    BRANCH: [],
    ROOMS: [],
    tableData: {
      allData: [],
      data: [],
      totalPages: 0,
      params: {}
    },
    USERS: [],
    USER_ACCESS: []
  },
  reducers: {
    clearMessage: (state) => {
      state.message = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(createRecord.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
          state.currentRequestId = action.meta.requestId
        }
      })
      .addCase(createRecord.fulfilled, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.currentRequestId = undefined
          state.message = action?.payload?.data?.message
        }
      })
      .addCase(createRecord.rejected, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.message = action?.payload?.data?.message || strings.SOMETHING_WENT_WRONG_REDUCER
          state.currentRequestId = undefined
        }
      })
      .addCase(deleteRow.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
          state.currentRequestId = action.meta.requestId
        }
      })
      .addCase(deleteRow.fulfilled, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.currentRequestId = undefined
          state.message = action?.payload?.data?.message
        }
      })
      .addCase(deleteRow.rejected, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.message = action?.payload?.data?.message || strings.SOMETHING_WENT_WRONG_REDUCER
          state.currentRequestId = undefined
        }
      })
      .addCase(editRow.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
          state.currentRequestId = action.meta.requestId
        }
      })
      .addCase(editRow.fulfilled, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.currentRequestId = undefined
          state.message = action?.payload?.data?.message
        }
      })
      .addCase(editRow.rejected, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.message = action?.payload?.data?.message || strings.SOMETHING_WENT_WRONG_REDUCER
          state.currentRequestId = undefined
        }
      })
      .addCase(getBranch.fulfilled, (state, action) => {
        state.BRANCH = action?.payload?.data?.data || []
      })
      .addCase(getAllRecords.fulfilled, (state, action) => {
        state.ROOMS = action?.payload?.data?.data || []
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.tableData = action?.payload?.data?.data || {}
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.USERS = action?.payload?.data?.data || []
      })
      .addCase(getAccessMapping.fulfilled, (state, action) => {
        state.USER_ACCESS = action?.payload?.data?.data || []
      })
  }
})

export const actions = datatablesSlice.actions

export default datatablesSlice.reducer
