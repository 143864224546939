// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import strings from '../../../configs/strings'

export const getData = createAsyncThunk('employees/getData', async params => {
  const response = await axios.post('/user/pagelist', params)
  return response
})

export const addUser = createAsyncThunk('employees/addUser', async (user, { dispatch }) => {
  const response = await axios.post('/user/register', {
    ...user,
    identification_id: user?.identification_id?.id,
    nationality_id: user?.nationality_id?.id,
    specialization_id: user?.specialization_id?.id,
    qualification_id: user?.qualification_id?.id,
    shift_id: user?.shift_id?.id,
    priviledge_id: user?.priviledge_id?.id,
    branch_id: user?.branch_id?.id
  })
  // await dispatch(getData(getState().users.params))
  await dispatch(getData())
  return response
})

export const editUser = createAsyncThunk('employees/editUser', async (user, { dispatch }) => {
  const response = await axios.post('/user/update', {
    ...user,
    identification_id: user?.identification_id?.id,
    nationality_id: user?.nationality_id?.id,
    specialization_id: user?.specialization_id?.id,
    qualification_id: user?.qualification_id?.id,
    shift_id: user?.shift_id?.id,
    priviledge_id: user?.priviledge_id?.id,
    branch_id: user?.branch_id?.id
  })
  // await dispatch(getData(getState().users.params))
  await dispatch(getData())
  return response
})

export const deleteRow = createAsyncThunk('employees/deleteRow', async (id, { dispatch }) => {
  const response = await axios.post('/user/delete', { id })
  await dispatch(getData())
  return response
})

export const getIdentification = createAsyncThunk('employees/identification/list', async () => {
  const response = await axios.post('/identification/list')
  return response
})

export const getNationality = createAsyncThunk('employees/nationality/list', async () => {
  const response = await axios.post('/nationality/list')
  return response
})

export const getSpecialization = createAsyncThunk('employees/specialization/list', async () => {
  const response = await axios.post('/specialization/list')
  return response
})

export const getQualification = createAsyncThunk('employees/qualification/list', async () => {
  const response = await axios.post('/qualification/list')
  return response
})

export const getShift = createAsyncThunk('employees/shift/list', async () => {
  const response = await axios.post('/shift/list')
  return response
})

export const getPriviledge = createAsyncThunk('employees/priviledge/list', async () => {
  const response = await axios.post('/priviledge/list')
  return response
})

export const getBranch = createAsyncThunk('employees/branch/list', async () => {
  const response = await axios.post('/branch/list')
  return response
})

export const getUser = createAsyncThunk('employees/getUser', async id => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})

export const getAllBaseData = createAsyncThunk('employees/getAllBaseData', async (id, { dispatch }) => {
  await dispatch(getNationality())
  await dispatch(getBranch())
  await dispatch(getPriviledge())
  await dispatch(getShift())
  await dispatch(getQualification())
  await dispatch(getSpecialization())
  await dispatch(getIdentification())
})


// const drop = [
//   { label: 'Value1', value: 1 },
//   { label: 'Value2', value: 2 }
// ]

export const appUsersSlice = createSlice({
  name: 'employees',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    message: null,
    loading: 'idle',
    currentRequestId: undefined,
    IDENTIFICATION: [],
    NATIONALITY: [],
    SPECIALIZATION: [],
    QULALIFICATION: [],
    SHIFT: [],
    PRIVILEDGE: [],
    BRANCH: [],
    USERS_LIST: [],
    tableData: {
      allData: [],
      data: [],
      totalPages: 0,
      params: {}
    }
  },
  reducers: {
    clearMessage: (state) => {
      state.message = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(addUser.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
          state.currentRequestId = action.meta.requestId
        }
      })
      .addCase(addUser.fulfilled, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.currentRequestId = undefined
          state.message = action.payload.data.message
        }
      })
      .addCase(addUser.rejected, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.message = action?.payload?.data?.message || strings.SOMETHING_WENT_WRONG_REDUCER
          state.currentRequestId = undefined
        }
      })
      .addCase(editUser.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
          state.currentRequestId = action.meta.requestId
        }
      })
      .addCase(editUser.fulfilled, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.currentRequestId = undefined
          state.message = action.payload.data.message
        }
      })
      .addCase(editUser.rejected, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.message = action?.payload?.data?.message || strings.SOMETHING_WENT_WRONG_REDUCER
          state.currentRequestId = undefined
        }
      })
      .addCase(getIdentification.fulfilled, (state, action) => {
        state.IDENTIFICATION = action.payload.data.data
      })
      .addCase(getSpecialization.fulfilled, (state, action) => {
        state.SPECIALIZATION = action.payload.data.data
      })
      .addCase(getQualification.fulfilled, (state, action) => {
        state.QULALIFICATION = action.payload.data.data
      })
      .addCase(getShift.fulfilled, (state, action) => {
        state.SHIFT = action.payload.data.data
      })
      .addCase(getPriviledge.fulfilled, (state, action) => {
        state.PRIVILEDGE = action.payload.data.data
      })
      .addCase(getBranch.fulfilled, (state, action) => {
        state.BRANCH = action.payload.data.data
      })
      .addCase(getNationality.fulfilled, (state, action) => {
        state.NATIONALITY = action.payload.data.data
        state.message = action?.payload?.data?.message
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.tableData = action?.payload?.data?.data || {}
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export const actions = appUsersSlice.actions

export default appUsersSlice.reducer
