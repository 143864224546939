// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import { fileHandler } from '../../files'

const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
  message: null,
  loading: 'idle',
  currentRequestId: undefined,
  tableData: {
    allData: [],
    data: [],
    totalPages: 0,
    params: {}
  },
  kidInfo: {},
  kidPackages: [],
  invDates: [new Date().setDate(-31), new Date()],
  kidFiles: [],
  userFiles: [],
  kidSession: {
    allData: [],
    data: [],
    totalPages: 0,
    params: {}
  },
  kidRoomSession: {
    allData: [],
    data: [],
    totalPages: 0,
    params: {}
  },
  userSession: {
    allData: [],
    data: [],
    totalPages: 0,
    params: {}
  },
  userRoomSession: {
    allData: [],
    data: [],
    totalPages: 0,
    params: {}
  },
  selectedUser: {}
}

export const downloadFilesKid = createAsyncThunk('profile/downloadFilesKid', async (id) => {
  const files = await fileHandler.getAllFiles({ filePath: `media/kid/kid_${id}/` })
  return files
})

export const uploadFileskid = createAsyncThunk('profile/uploadFileskid', async ({ id, files }, { dispatch }) => {
  const response = await fileHandler.uploadFiles({ filePath: `media/kid/kid_${id}/`, files })
  await dispatch(downloadFilesKid(id))
  return response
})

export const downloadFilesUser = createAsyncThunk('profile/downloadFilesUser', async (id) => {
  const files = await fileHandler.getAllFiles({ filePath: `media/emp/emp_${id}/` })
  return files
})

export const uploadFilesUser = createAsyncThunk('profile/uploadFilesUser', async ({ id, files }, { dispatch }) => {
  const response = await fileHandler.uploadFiles({ filePath: `media/emp/emp_${id}/`, files })
  await dispatch(downloadFilesUser(id))
  return response
})

export const getUser = createAsyncThunk('profile/getUser', async id => {
  const response = await axios.post('/profile/user/info', { id })
  return response
})

export const getKidInfo = createAsyncThunk('profile/getKidInfo', async id => {
  const response = await axios.post('/profile/kid/info', { id })
  return response
})

export const getKidPackages = createAsyncThunk('profile/getKidPackages', async id => {
  const response = await axios.post('/profile/kid/subscription', { id })
  return response
})

export const getInvoiceData = createAsyncThunk('profile/getInvoiceData', async (params, { getState }) => {
  const { invDates, kidInfo } = getState().profile
  const dates = !Array.isArray(invDates) || invDates.length === 1 ? [invDates, invDates] : invDates
  const response = await axios.post('/profile/kid/invoice', { ...params, dates, kid_id: kidInfo.id })
  return response
})

export const getKidSessions = createAsyncThunk('profile/getKidSessions', async (params, { getState }) => {
  const { kidInfo } = getState().profile
  const response = await axios.post('/profile/kid/session', { ...params, kid_id: kidInfo.id })
  return response
})

export const getUserSessions = createAsyncThunk('profile/getUserSessions', async (params, { getState }) => {
  const { selectedUser } = getState().profile
  const response = await axios.post('/profile/user/session', { ...params, user_id: selectedUser.id })
  return response
})

export const getKidRoomSessions = createAsyncThunk('profile/getKidRoomSessions', async (params, { getState }) => {
  const { kidInfo } = getState().profile
  const response = await axios.post('/profile/kid/roomsession', { ...params, kid_id: kidInfo.id })
  return response
})

export const getUserRoomSessions = createAsyncThunk('profile/getUserRoomSessions', async (params, { getState }) => {
  const { selectedUser } = getState().profile
  const response = await axios.post('/profile/user/roomsession', { ...params, user_id: selectedUser.id })
  return response
})

export const deleteKid = createAsyncThunk('profile/deleteKid', async (id, { dispatch }) => {
  const response = await axios.post('/kid/delete', { id })
  await dispatch(getKidInfo(id))
  return response
})

export const deleteUser = createAsyncThunk('profile/deleteUser', async (id, { dispatch }) => {
  const response = await axios.post('/user/delete', { id })
  await dispatch(getUser(id))
  return response
})

export const updatePassword = createAsyncThunk('profile/updatePassword', async (data, { getState }) => {
  const { selectedUser } = getState().profile
  const response = await axios.post('/user/update', { ...data, id: selectedUser.id })
  return response
})

export const getKidProfileData = createAsyncThunk('profile/getKidProfileData', async (id, { dispatch }) => {
  await dispatch(getKidInfo(id))
  await dispatch(getKidPackages(id))
  await dispatch(getInvoiceData())
  await dispatch(downloadFilesKid(id))
})

export const getUserProfileData = createAsyncThunk('profile/getUserProfileData', async (id, { dispatch }) => {
  await dispatch(getUser(id))
  await dispatch(downloadFilesUser(id))
})

export const deleteSubscription = createAsyncThunk('profile/deleteSubscription', async ({ id, kid_id }, { dispatch }) => {
  const response = await axios.post('/subscription/delete', { id })
  await dispatch(getKidPackages(kid_id))
  return response
})

export const toggleFreezeSubscription = createAsyncThunk('profile/toggleFreezeSubscription', async ({ id, kid_id, freeze_days }, { dispatch }) => {
  const response = await axios.patch('/subscription/freeze', { id, freeze_days })
  await dispatch(getKidPackages(kid_id))
  return response
})

export const appUsersSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setDate: (state, action) => {
      state.invDates = action.payload
    },
    clearMessage: (state) => {
      state.message = null
    },
    resetData: () => ({ ...initialState })
  },
  extraReducers: builder => {
    builder
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload?.data?.data || {}
      })
      .addCase(getKidInfo.fulfilled, (state, action) => {
        state.kidInfo = action.payload?.data?.data || {}
      })
      .addCase(getKidPackages.fulfilled, (state, action) => {
        state.kidPackages = action.payload?.data?.data || []
      })
      .addCase(getInvoiceData.fulfilled, (state, action) => {
        state.tableData = action?.payload?.data?.data || {}
      })
      .addCase(downloadFilesKid.fulfilled, (state, action) => {
        state.kidFiles = action?.payload
      })
      .addCase(getKidSessions.fulfilled, (state, action) => {
        state.kidSession = action.payload?.data?.data || []
      })
      .addCase(getKidRoomSessions.fulfilled, (state, action) => {
        state.kidRoomSession = action.payload?.data?.data || []
      })
      .addCase(getUserSessions.fulfilled, (state, action) => {
        state.userSession = action.payload?.data?.data || []
      })
      .addCase(getUserRoomSessions.fulfilled, (state, action) => {
        state.userRoomSession = action.payload?.data?.data || []
      })
      .addCase(downloadFilesUser.fulfilled, (state, action) => {
        state.userFiles = action?.payload
      })
  }
})

export const actions = appUsersSlice.actions

export default appUsersSlice.reducer
