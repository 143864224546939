// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import strings from '../../../configs/strings'

export const getData = createAsyncThunk('coupon/getData', async (params) => {
  const response = await axios.post('/coupon/pagelist', params)
  return response
})

export const createRecord = createAsyncThunk('coupon/create', async (data, { dispatch }) => {
  const payload = {
    ...data,
    type: data.type.value,
    branch_id: data?.branch_id?.id
  }
  const response = await axios.post('/coupon/create', payload)
  await dispatch(getData())
  return response
})

export const getBranch = createAsyncThunk('coupon/branch/list', async () => {
  const response = await axios.post('/branch/list')
  return response
})

export const deleteRow = createAsyncThunk('coupon/deleteRow', async (id, { dispatch }) => {
  const response = await axios.post('/coupon/delete', { id })
  await dispatch(getData())
  return response
})

export const datatablesSlice = createSlice({
  name: 'coupon',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    message: null,
    loading: 'idle',
    BRANCH: [],
    DISCTYPE: [
      { label: 'Amount', value: 'amount' },
      { label: 'Percent', value: 'percent' }
    ],
    tableData: {
      allData: [],
      data: [],
      totalPages: 0,
      params: {}
    }
  },
  reducers: {
    clearMessage: (state) => {
      state.message = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(createRecord.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
          state.currentRequestId = action.meta.requestId
        }
      })
      .addCase(createRecord.fulfilled, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.currentRequestId = undefined
          state.message = action?.payload?.data?.message
        }
      })
      .addCase(createRecord.rejected, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.message = action?.payload?.data?.message || strings.SOMETHING_WENT_WRONG_REDUCER
          state.currentRequestId = undefined
        }
      })
      .addCase(getBranch.fulfilled, (state, action) => {
        state.BRANCH = action.payload.data.data
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.tableData = action?.payload?.data?.data || {}
      })
  }
})

export const actions = datatablesSlice.actions

export default datatablesSlice.reducer
