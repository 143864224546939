// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import strings from '../../../configs/strings'
import { fileHandler } from '../../files'

export const downloadFiles = createAsyncThunk('subscription/downloadFiles', async ({ data, filePath }) => {
  const files = {}
  const downloads = data?.data
    .map(async ({ id, image }) => {
      files[`P${id}`] = image === '/product.png' ? image : await fileHandler.downloadFiles({ fileName: image, filePath })
    })
  await Promise.all(downloads)
  return files
})

export const getProducts = createAsyncThunk('subscription/getProducts', async params => {
  const response = await axios.get('/apps/ecommerce/products', { params })
  return { params, data: response.data }
})

export const addToCart = createAsyncThunk('subscription/addToCart', async (id, { dispatch, getState }) => {
  const response = await axios.post('/apps/ecommerce/cart', { productId: id })
  await dispatch(getProducts(getState().ecommerce.params))
  return response.data
})

export const getWishlistItems = createAsyncThunk('subscription/getWishlistItems', async () => {
  const response = await axios.get('/apps/ecommerce/wishlist')
  return response.data
})

export const deleteWishlistItem = createAsyncThunk('subscription/deleteWishlistItem', async (id, { dispatch }) => {
  const response = await axios.delete(`/apps/ecommerce/wishlist/${id}`)
  dispatch(getWishlistItems())
  return response.data
})

export const getCartItems = createAsyncThunk('subscription/getCartItems', async () => {
  const response = await axios.get('/apps/ecommerce/cart')
  return response.data
})

export const getProduct = createAsyncThunk('subscription/getProduct', async slug => {
  const response = await axios.get(`/apps/ecommerce/products/${slug}`)
  return response.data
})

export const addToWishlist = createAsyncThunk('subscription/addToWishlist', async id => {
  await axios.post('/apps/ecommerce/wishlist', { productId: id })
  return id
})

export const deleteCartItem = createAsyncThunk('subscription/deleteCartItem', async (id, { dispatch }) => {
  await axios.delete(`/apps/ecommerce/cart/${id}`)
  dispatch(getCartItems())
  return id
})

export const getPackage = createAsyncThunk('subscription/getPackage', async (_, { dispatch }) => {
  const response = await axios.post('sale/package/list', { getAll: true })
  dispatch(downloadFiles({ data: response.data, filePath: 'media/package/' }))
  return response
})

export const getAllProducts = createAsyncThunk('subscription/getAllProducts', async (_, { dispatch }) => {
  const response = await axios.post('sale/product/list', { getAll: true })
  dispatch(downloadFiles({ data: response.data, filePath: 'media/product/' }))
  return response
})

export const serachParent = createAsyncThunk('subscription/search', async ({ query, checked }) => {
  const response = await axios.post('/parent/search', { query, checked })
  return response
})

export const serachKids = createAsyncThunk('subscription/serachKids', async (search, { getState }) => {
  const response = await axios.post('/sale/kidslist',
    { parent_ids: getState().subscription.parentInfo.map(p => p.id) })
  return response
})

export const validateCoupon = createAsyncThunk('subscription/validateCoupon', async (data, { getState }) => {
  const response = await axios.post('/sale/coupon', { ...data, coupon: getState().subscription.coupon })
  return response
})

export const processNewSubcription = createAsyncThunk('subscription/processNewSubcription', async ({ salesData, data }, { getState }) => {
  const { cart, couponData, parentInfo } = getState().subscription
  const response = await axios.post('/sale/subscription/create', {
    cart,
    coupon_id: couponData?.id,
    salesData: { ...salesData, ...data },
    parentInfo: parentInfo?.[0]
  })
  return response
})

export const processNewSales = createAsyncThunk('subscription/processNewSales', async ({ salesData, data }, { getState }) => {
  const { cart, couponData, parentInfo } = getState().subscription
  const response = await axios.post('/sale/sale/create', {
    cart,
    coupon_id: couponData?.id,
    salesData: { ...salesData, ...data },
    parentInfo: parentInfo?.[0]
  })
  return response
})

export const getParent = createAsyncThunk('subscription/getParent', async (data) => {
  const response = await axios.post('/parent/info', data)
  return response
})

export const appEcommerceSlice = createSlice({
  name: 'subscription',
  initialState: {
    cart: [],
    params: {},
    wishlist: [],
    totalProducts: 0,
    productDetail: {},
    products: [],
    package: [],
    PARENTS: [],
    parentInfo: [],
    loading: "idle",
    kidsList: [],
    selectedKids: [],
    couponData: {
    },
    coupon: "",
    message: null,
    loading: 'idle',
    METHOD: [
      { id: 'cash', name_en: 'Cash', name_ar: "نقدًا" },
      { id: 'pos', name_en: 'POS', name_ar: "نقاط بيع" }
    ],
    files: {}
  },
  reducers: {
    resetCart: (state) => {
      state.cart = []
      state.selectedKids = []
      state.couponData = {}
      state.coupon = ""
      state.parentInfo = []
    },
    clearMessage: (state) => {
      state.message = null
    },
    setCoupon: (state, action) => {
      state.coupon = action.payload
    },
    clearCoupon: (state) => {
      state.coupon = ""
      state.couponData = {}
    },
    setParent: (state, action) => {
      state.parentInfo = action.payload
    },
    selectedKids: (state, action) => {
      state.selectedKids = action.payload
    },
    addToCart: (state, action) => {
      state.cart.push({ ...action.payload, kids: state.selectedKids })
    },
    removeFromCart: (state, action) => {
      state.cart = state.cart.filter((_, i) => i !== action.payload)
    },
    updateQty: (state, action) => {
      state.cart.map((item, i) => {
        if (i === action.payload.index) {
          item.product.qty = action.payload.qty
          return item
        } else {
          return item
        }
      })
      state.cart = state.cart.filter(p => p.product.qty > 0)
    },
    appendKids: (state, action) => {
      state.selectedKids.push(action.payload)
    },
    updateKids: (state, action) => {
      state.selectedKids = [action.payload]
    },
    updateKidList: (state, action) => {
      state.kidsList = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getProducts.fulfilled, (state, action) => {
        state.params = action.payload.params
        state.products = action.payload.data.products
        state.totalProducts = action.payload.data.total
      })
      .addCase(getWishlistItems.fulfilled, (state, action) => {
        state.wishlist = action.payload.products
      })
      .addCase(getCartItems.fulfilled, (state, action) => {
        state.cart = action.payload.products
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.productDetail = action.payload.product
      })
      .addCase(getPackage.fulfilled, (state, action) => {
        state.package = action.payload.data.data
      })
      .addCase(getAllProducts.fulfilled, (state, action) => {
        state.products = action.payload.data.data
      })
      .addCase(serachParent.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
          state.currentRequestId = action.meta.requestId
          state.PARENTS = []
        }
      })
      .addCase(serachParent.fulfilled, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.currentRequestId = undefined
          state.PARENTS = action.payload.data.data
        }
      })
      .addCase(serachParent.rejected, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.currentRequestId = undefined
        }
      })
      .addCase(processNewSubcription.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
          state.currentRequestId = action.meta.requestId
          state.PARENTS = []
        }
      })
      .addCase(processNewSubcription.fulfilled, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.currentRequestId = undefined
        }
      })
      .addCase(processNewSubcription.rejected, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.message = action?.payload?.data?.message || strings.SOMETHING_WENT_WRONG_REDUCER
          state.currentRequestId = undefined
        }
      })
      .addCase(processNewSales.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
          state.currentRequestId = action.meta.requestId
          state.PARENTS = []
        }
      })
      .addCase(processNewSales.fulfilled, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.currentRequestId = undefined
        }
      })
      .addCase(processNewSales.rejected, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.message = action?.payload?.data?.message || strings.SOMETHING_WENT_WRONG_REDUCER
          state.currentRequestId = undefined
        }
      })
      .addCase(validateCoupon.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
          state.currentRequestId = action.meta.requestId
        }
      })
      .addCase(validateCoupon.fulfilled, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.currentRequestId = undefined
          state.message = action.payload.data.message
          state.couponData = action.payload?.data?.data || {}
        }
      })
      .addCase(validateCoupon.rejected, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.message = action?.payload?.data?.message || strings.SOMETHING_WENT_WRONG_REDUCER
          state.currentRequestId = undefined
        }
      })
      .addCase(serachKids.fulfilled, (state, action) => {
        state.kidsList = action.payload.data.data
      })
      .addCase(getParent.fulfilled, (state, action) => {
        state.parentInfo = [action?.payload?.data?.data] || []
      })
      .addCase(downloadFiles.fulfilled, (state, action) => {
        state.files = action?.payload || {}
      })
  }
})

export const actions = appEcommerceSlice.actions

export default appEcommerceSlice.reducer
