import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import axios from 'axios'

export const getPremisesKid = createAsyncThunk('admin/getPremisesKid', async params => {
    const response = await axios.post('/kidsession/pagelist', params)
    return response
})

export const getRoomsKid = createAsyncThunk('admin/getRoomsKid', async params => {
    const response = await axios.post('/kidroomsession/pagelist', params)
    return response
})

export const getUserLoginReport = createAsyncThunk('admin/getUserLoginReport', async params => {
    const response = await axios.post('/user/loginreport', params)
    return response
})

export const getCardData = createAsyncThunk('admin/getCardData', async () => {
    const response = await axios.get('/admin/dashboard')
    return response
})

export const getAllReports = createAsyncThunk('admin/getAllReports', async (_, { dispatch }) => {
    await dispatch(getPremisesKid())
    await dispatch(getRoomsKid())
    await dispatch(getUserLoginReport())
    await dispatch(getCardData())
    return "OK"
})

export const datatablesSlice = createSlice({
    name: 'admin',
    initialState: {
        data: [],
        total: 1,
        params: {},
        allData: [],
        message: null,
        loading: 'idle',
        getPremisesKid: {
            allData: [],
            data: [],
            totalPages: 0,
            params: {}
        },
        getRoomsKid: {
            allData: [],
            data: [],
            totalPages: 0,
            params: {}
        },
        getUserLoginReport: {
            allData: [],
            data: [],
            totalPages: 0,
            params: {}
        },
        cardData: {
            totalKids: 0,
            chekinKids: 0,
            activeEmployee: 0,
            todayRevenue: 0
        }
    },
    reducers: {
        clearMessage: (state) => {
            state.message = null
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getPremisesKid.fulfilled, (state, action) => {
                state.getPremisesKid = action?.payload?.data?.data || {}
            })
            .addCase(getRoomsKid.fulfilled, (state, action) => {
                state.getRoomsKid = action?.payload?.data?.data || {}
            })
            .addCase(getUserLoginReport.fulfilled, (state, action) => {
                state.getUserLoginReport = action?.payload?.data?.data || {}
            })
            .addCase(getCardData.fulfilled, (state, action) => {
                state.cardData = action?.payload?.data?.data || {}
            })
    }
})

export const actions = datatablesSlice.actions

export default datatablesSlice.reducer
