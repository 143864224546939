export default {
    PROCESSING: "PROCESSING",
    SEARCH: "SEARCH",
    SAVE: "SAVE",
    EDIT: "EDIT",
    COLLECT_PAYMENT: "COLLECT_PAYMENT",
    RESET: "RESET",
    CANCEL: "CANCEL",
    CLEAR: "CLEAR",
    NEXT: "NEXT",
    PREVIOUS: "PREVIOUS",
    UPLOAD: "UPLOAD",
    NAME: "NAME",
    NAME_EN: "NAME_EN",
    ACTION: "ACTION",
    ENTER_NAME_EN: "ENTER_NAME_EN",
    NAME_AR: "NAME_AR",
    ENTER_NAME_AR: "ENTER_NAME_AR",
    VALUE: "VALUE",
    TAX_TYPE: "TAX_TYPE",
    SELECT_FROM_DROPDOWN: "SELECT_FROM_DROPDOWN",
    EMAIL: "EMAIL",
    MOBILE: "MOBILE",
    EMERGENCY_MOBILE: "EMERGENCY_MOBILE",
    DOB: "DOB",
    ENTER_DOB: "ENTER_DOB",
    ENTER_VALID_VALUE: "ENTER_VALID_VALUE",
    PASSWORD: "PASSWORD",
    ENTER_PASSWORD: "ENTER_PASSWORD",
    INDENTIFICATION_VALUE: "INDENTIFICATION_VALUE",
    QULALIFICATION: "QULALIFICATION",
    SHIFT: "SHIFT",
    PRIVILEDGE: "PRIVILEDGE",
    NEW_EMPLOYEE: "NEW_EMPLOYEE",
    PRODUCTS: "PRODUCTS",
    ROOMS: "ROOMS",
    ROOM: "ROOM",
    PACKAGE: "PACKAGE",
    NUMERIC_NAME: "NUMERIC_NAME",
    CAPACITY: "CAPACITY",
    LIMIT: "LIMIT",
    VALIDITY: "VALIDITY",
    COUPON: "COUPON",
    CODE: "CODE",
    DISCOUNT: "DISCOUNT",
    MIN_VALUE: "MIN_VALUE",
    TYPE: "TYPE",
    COUPON_VALIDITY: "COUPON_VALIDITY",
    ADDRESS: "ADDRESS",
    BRANCH: "BRANCH",
    TAX_SELECTION: "TAX_SELECTION",
    INDENTIFICATION_TYPE: "INDENTIFICATION_TYPE",
    NATIONALITY: "NATIONALITY",
    SPECIALIZATION: "SPECIALIZATION",
    SOMETHING_WENT_WRONG_REDUCER: "SOMETHING_WENT_WRONG_REDUCER",
    ERROR: "ERROR",
    NOTES: "NOTES",
    IMAGE: "IMAGE",
    HIJRI: "HIJRI",
    ADD_A_KID: "ADD_A_KID",
    KID_INFO: "KID_INFO",
    KIDS: "KIDS",
    PARENTS: "PARENTS",
    PARENTS_SEARCH_BY: "PARENTS_SEARCH_BY",
    CHECKIN: "CHECKIN",
    CHECKOUT: "CHECKOUT",
    DUPES_BADGE: "DUPES_BADGE",
    NO_SUBSCRIPTION: "NO_SUBSCRIPTION",
    CHECKIN_DONE: "CHECKIN_DONE",
    SEND_OTP: "SEND_OTP",
    RESEND_OTP: "RESEND_OTP",
    VERIFY_OTP: "VERIFY_OTP",
    OTP_VERIFICATION_SUCCESS: "OTP_VERIFICATION_SUCCESS",
    INVALID_OTP: "INVALID_OTP",
    NO_ROOM_ASSIGNED: "NO_ROOM_ASSIGNED",
    PASSWORD_MISSING: "PASSWORD_MISSING",
    NO_KID_SELECTED: "NO_KID_SELECTED",
    VALIDATING_COUPON: "VALIDATING_COUPON",
    CASH: "CASH",
    POS: "POS",
    REMARKS: "REMARKS",
    METHOD: "METHOD",
    PAYMENT_INFO: "PAYMENT_INFO",
    PLS_SELECT_FEATURES: "PLS_SELECT_FEATURES",
    PRIVILEDGE_CREATED: "PRIVILEDGE_CREATED",
    ACCESS: "ACCESS",
    VIEW: "VIEW",
    CREATE: "CREATE",
    UPDATE: "UPDATE",
    DELETE: "DELETE",
    HOME: "HOME",
    SUBJECT: "SUBJECT",
    MESSAGE: "MESSAGE",
    GOTO_CHECKIN: "GOTO_CHECKIN",
    SMS: "SMS",
    PROMOTION: "PROMOTION",
    SEND: "SEND",
    EXCEL_UPLOAD: "EXCEL_UPLOAD",
    INSUFFICIENT_STOCK: "INSUFFICIENT_STOCK",
    LOGIN: "LOGIN",
    LOGOUT: "LOGOUT",
    WELCOME_HI5: "WELCOME_HI5",
    CHECKED_IN_KIDS_LIST: "CHECKED_IN_KIDS_LIST",
    CHECKED_OUT_KIDS_LIST: "CHECKED_OUT_KIDS_LIST",
    ALLOWED_FILE_SIZE: "ALLOWED_FILE_SIZE",
    PARENT_DETAILS: "PARENT_DETAILS",
    PARENTS_INFO: "PARENTS_INFO",
    KID_DETAILS: "KID_DETAILS",
    KID_CHECKIN_CHECKOUT: "KID_CHECKIN_CHECKOUT",
    SUMMARY: "SUMMARY",
    KIDS_SUMMARY: "KIDS_SUMMARY",
    ENTER_A_VALID_BADGE: "ENTER_A_VALID_BADGE",
    ADD_SUBSCRIPTION: "ADD_SUBSCRIPTION",
    VAT_NUMBER: "VAT_NUMBER",
    UPLOADED: "UPLOADED",
    SEND_INVOICE: "SEND_INVOICE",
    DOWNLOAD: "DOWNLOAD",
    PRINTA4: "PRINTA4",
    THERMAL_PRINT: "THERMAL_PRINT",
    INVOICE: "INVOICE",
    DATE_ISSUED: "DATE_ISSUED",
    DUE_DATE: "DUE_DATE",
    INVOICE_TO: "INVOICE_TO",
    CASH_SALES: "CASH_SALES",
    PAYMENT_DETAILS: "PAYMENT_DETAILS",
    TOTAL_VALUE: "TOTAL_VALUE",
    QTY: "QTY",
    TOTAL: "TOTAL",
    PARENT: "PARENT",
    SALES_PERSON: "SALES_PERSON",
    SUBTOTAL: "SUBTOTAL",
    VAT: "VAT",
    RETURN: "RETURN",
    ROOM_ID: "ROOM_ID",
    ASSIGNED_USER: "ASSIGNED_USER",
    DELETE_SENT: "DELETE_SENT",
    ARE_U_SURE: "ARE_U_SURE",
    YOU_WONT_ABLE_REVERT: "YOU_WONT_ABLE_REVERT",
    BADGE: "BADGE",
    SUBSCRIPTION: "SUBSCRIPTION",
    SINCE: "SINCE",
    ROLE: "ROLE",
    PRIVILEDGE_LIST: "PRIVILEDGE_LIST",
    TOTAL_USERS_WITH_ROLES: "TOTAL_USERS_WITH_ROLES",
    EMPLOYEE_LIST_AND_ROLES: "EMPLOYEE_LIST_AND_ROLES",
    USERS: "USERS",
    EDIT_PRIVILEDGE: "EDIT_PRIVILEDGE",
    FEATURES: "FEATURES",
    ADD_A_NEW_ROLE: "ADD_A_NEW_ROLE",
    EDIT_A_ROLE: "EDIT_A_ROLE",
    ADD_A_NEW_ROLE_IF_NOT_EXIST: "ADD_A_NEW_ROLE_IF_NOT_EXIST",
    SET_ROLE_PERMISSIONS: "SET_ROLE_PERMISSIONS",
    ENTER_A_VALID_ROLE_NAME: "ENTER_A_VALID_ROLE_NAME",
    ROLE_PERMISSIONS: "ROLE_PERMISSIONS",
    ADMINISTRATOR_ACCESS: "ADMINISTRATOR_ACCESS",
    ALLOW_FULL_ACCESS: "ALLOW_FULL_ACCESS",
    SELECT_ALL: "SELECT_ALL",
    SHOW: "SHOW",
    ENTRIES: "ENTRIES",
    ID: "ID",
    SALE_PRICE: "SALE_PRICE",
    STOCK: "STOCK",
    ENTER_STOCK_TO_ADD: "ENTER_STOCK_TO_ADD",
    ADD: "ADD",
    ADD_STOCK: "ADD_STOCK",
    STOCK_UPDATED: "STOCK_UPDATED",
    PLS_CHECK_UPLOADED_EXCEL: "PLS_CHECK_UPLOADED_EXCEL",
    COMMA_SEPERATED_NUMBERS: "COMMA_SEPERATED_NUMBERS",
    REVOKE: "REVOKE",
    YES: "YES",
    MOBILE_NUMBER_MISSING: "MOBILE_NUMBER_MISSING",
    REGISTERED: "REGISTERED",
    USER_EXISTS: "USER_EXISTS",
    SESSION_EXPIRED: "SESSION_EXPIRED",
    NOT_AUTHORISED: "NOT_AUTHORISED",
    NOT_FOUND: "NOT_FOUND",
    UPDATED: "UPDATED",
    USER_NOT_FOUND: "USER_NOT_FOUND",
    INVALID_PASSWORD: "INVALID_PASSWORD",
    SUCCESS: "SUCCESS",
    DELETED: "DELETED",
    EXISTS: "EXISTS",
    CREATED: "CREATED",
    CHECKOUT_COMPLETED: "CHECKOUT_COMPLETED",
    ROOM_LOGIN_NOT_FOUND: "ROOM_LOGIN_NOT_FOUND",
    LESS_CART_VALUE: "LESS_CART_VALUE",
    COUPON_EXPIRED: "COUPON_EXPIRED",
    COUPON_NOT_FOR_THIS_BRANCH: "COUPON_NOT_FOR_THIS_BRANCH",
    COUPON_USE_LIMIT_EXHAUSTED: "COUPON_USE_LIMIT_EXHAUSTED",
    COUPON_NOT_FOUND: "COUPON_NOT_FOUND",
    COUPON_APPLIED: "COUPON_APPLIED",
    SALES_COMPLETED: "SALES_COMPLETED",
    PROCESSING_SMS: "PROCESSING_SMS",
    EMPLOYEES: "EMPLOYEES",
    SELECT_ROOM_TO_LOGIN: "SELECT_ROOM_TO_LOGIN",
    ITEM_TOTAL: "ITEM_TOTAL",
    APPLY: "APPLY",
    PRICE_DETAILS: "PRICE_DETAILS",
    BY_BADGE: "BY_BADGE",
    BY_PARENT: "BY_PARENT",
    BY_KID: "BY_KID",
    CART_ITEMS: "CART_ITEMS",
    TWO_STEP_VERIFICATION: "TWO_STEP_VERIFICATION",
    WE_WILL_SEND_OTP: "WE_WILL_SEND_OTP",
    TYPE_OTP: "TYPE_OTP",
    HI5: "HI5",
    SALES: "SALES",
    SUPERVISOR: "SUPERVISOR",
    REPORTS: "REPORTS",
    PRINT_PREVIEW: "PRINT_PREVIEW",
    APP: "APP",
    HI5CENTER: "HI5CENTER",
    CART: "CART",
    SALE_ITEMS: "SALE_ITEMS",
    PAYMENT: "PAYMENT",
    SALES_PAYMENT: "SALES_PAYMENT",
    NO_DATA_TO_DISPLAY: "NO_DATA_TO_DISPLAY",
    ADMIN_DASHBOARD: "ADMIN_DASHBOARD",
    KID_ROOMS_STATUS: "KID_ROOMS_STATUS",
    ATTENDANCE: "ATTENDANCE",
    STATUS: "STATUS",
    DATE: "DATE",
    DASHBOARD: "DASHBOARD",
    REQUEST_SENT: "REQUEST_SENT",
    ADMIN_OVERRIDE: "ADMIN_OVERRIDE",
    KID_CHECKOUT_FOR: "KID_CHECKOUT_FOR",
    OTP_PARENT_MOBILE: "OTP_PARENT_MOBILE",
    OTP_ADMIN_MOBILE: "OTP_ADMIN_MOBILE",
    MAX_10_DAYS: "MAX_10_DAYS",
    "Select a branch": "Select a branch",
    INACTIVE: "INACTIVE",
    ACTIVE: "ACTIVE",
    ACTIVATE: "ACTIVATE",
    WAITING: "WAITING",
    ACTIVATED_ON: "ACTIVATED_ON",
    VISITS_REMAINING: "VISITS_REMAINING",
    DAYS_REMAINING: "DAYS_REMAINING",
    PHOTOS: "PHOTOS",
    DROP_HERE: "DROP_HERE",
    REVENUE: "REVENUE",
    SESSIONS: "SESSIONS",
    ROOM_SESSION: "ROOM_SESSION",
    SIGNIN: "SIGNIN",
    USER_SUMMARY: "USER_SUMMARY",
    ACCOUNT: "ACCOUNT",
    SECURITY: "SECURITY",
    UPDATE_PASSWORD: "UPDATE_PASSWORD",
    PASSWORD_INFO1: "PASSWORD_INFO1",
    PASSWORD_INFO2: "PASSWORD_INFO2",
    NEW_PASSWORD: "NEW_PASSWORD",
    CONFIRM_PASSWORD: "CONFIRM_PASSWORD",
    PASSWORD_MUST_MATCH: "PASSWORD_MUST_MATCH",
    IP: "IP",
    IN_PREMISE_KIDS: "IN_PREMISE_KIDS",
    ACTIVE_EMPLOYEE: "ACTIVE_EMPLOYEE",
    TODAY_REVENUE: "TODAY_REVENUE",
    CART_LESS_THAN_DISCOUNT: "CART_LESS_THAN_DISCOUNT",
    SEND_A_MSG_KID_ROOM: "SEND_A_MSG_KID_ROOM",
    MESSAGE_SENT: "MESSAGE_SENT",
    INV_COUNT: "INV_COUNT",
    CHATS: "CHATS",
    START_A_CHAT: "START_A_CHAT",
    PASSWORD_MIN_6_CHARS: "PASSWORD_MIN_6_CHARS",
    USER_ROOM_SESSIONS: "USER_ROOM_SESSIONS",
    VALUE_CANT_BE_LESS_THAN: "VALUE_CANT_BE_LESS_THAN",
    PROFILE: "PROFILE",
    TAXABLE_VALUE: 'TAXABLE_VALUE',
    CHEKEDIN: "CHEKEDIN",
    CHECKEDOUT: 'CHECKEDOUT',
    VALIDITY_DAYS: "VALIDITY_DAYS",
    SELECT_MOBILE: "SELECT_MOBILE",
    SENDANTECH: "SENDANTECH",
    CANCELLED: "CANCELLED",
    REST_PER_HOUR_CHARGE: "REST_PER_HOUR_CHARGE",
    FIRST_HOUR_CHARGE: "FIRST_HOUR_CHARGE",
    MARGIN_MINUTES: "MARGIN_MINUTES",
    DAILY_HOUR_LIMITS: "DAILY_HOUR_LIMITS",
    TIME: "TIME",
    SESSION_HOURS: "SESSION_HOURS",
    EXTRA_HOURS: "EXTRA_HOURS",
    MAKE_PAYMENT_FOR_OVERTIME: "MAKE_PAYMENT_FOR_OVERTIME",
    FREEZE: "FREEZE",
    RESUME: "RESUME",
    YOU_MAY_REVERT_THIS_LATER: "YOU_MAY_REVERT_THIS_LATER",
    SUBSCRIPTION_FREEZED: "SUBSCRIPTION_FREEZED",
    ARE_U_SURE_FOR_RESUME_SUBS: "ARE_U_SURE_FOR_RESUME_SUBS",
    CLICK_TO_RESUME_SUBSCRIPTION: "CLICK_TO_RESUME_SUBSCRIPTION",
    KID_SESSION_NOT_ACTIVE: "KID_SESSION_NOT_ACTIVE",
    CANCELLED: "CANCELLED",
    CANCEL_INVOICE: "CANCEL_INVOICE",
    INVOICE_CANCELLED: "INVOICE_CANCELLED",
    ALREADY_CANCELLED: "ALREADY_CANCELLED",
    ORIGNAL_INVOICE: "ORIGNAL_INVOICE",
    CANCELLED_INVOCIE_ID: "CANCELLED_INVOCIE_ID",
    THIS_IS_A_CANCELED_INVOICE: "THIS_IS_A_CANCELED_INVOICE",
    PDF: "PDF",
    EXPORT_AS_PDF: "EXPORT_AS_PDF",
    CLICK_TO_EXPORT_AS_PDF: "CLICK_TO_EXPORT_AS_PDF",
    CLICK_TO_EXPORT_AND_OUTSIDE: "CLICK_TO_EXPORT_AND_OUTSIDE",
    SUBSCRIPTION_REPORT: "SUBSCRIPTION_REPORT",
    SUBSCRIPTIONS: "SUBSCRIPTIONS",
    MONITOR: "MONITOR",
    KIDS_WISE_SUBS: "KIDS_WISE_SUBS",
    PARENT_WISE_SUBS: 'PARENT_WISE_SUBS',
    CUSTOM_REPORTS: "CUSTOM_REPORTS",
    LOYAL_CUSTOMER: "LOYAL_CUSTOMER",
    LOYAL_REMARKS: "LOYAL_REMARKS",
    PAUSE_SUBSCRIPTION: "PAUSE_SUBSCRIPTION",
    ENTER_SUBS_FREEZE_DAYS: "ENTER_SUBS_FREEZE_DAYS",
    FREEZE_DAYS_NOT_VALID: 'FREEZE_DAYS_NOT_VALID',
    DAILY_LIMITS_IN_MINUTES: "DAILY_LIMITS_IN_MINUTES"
}