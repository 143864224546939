// ** React Imports
import { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'

// ** Redux Imports
import { Provider } from 'react-redux'
import { store } from './redux/store'

// ** Intl, CASL & ThemeColors Context
import ability from './configs/acl/ability'
import { ToastContainer } from 'react-toastify'
import { AbilityContext } from './utility/context/Can'
import { ThemeContext } from './utility/context/ThemeColors'

// ** i18n
import './configs/i18n'

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'

// ** Ripple Button
import './@core/components/ripple-button'

// ** Fake Database
import './@fake-db'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'

// ** Service Worker
import * as serviceWorker from './serviceWorker'

import './firebase'
import SocketIO from './hifive/chat/socket.io'

// import { initializeApp } from 'firebase/app'
// import { getFirestore } from 'firebase/firestore'

// initializeApp({
//   apiKey: "AIzaSyCYVT_11hlGNj91vqDOijwDa1GivnNB_dg",
//   authDomain: "hi-5-a0639.firebaseapp.com",
//   projectId: "hi-5-a0639",
//   storageBucket: "hi-5-a0639.appspot.com",
//   messagingSenderId: "761977874124",
//   appId: "1:761977874124:web:b93d12714c6223761d0469",
//   measurementId: "G-FP58KZ5C3L",
//   databaseURL: "hi-5-a0639-default-rtdb.firebaseio.com"
// })

// getFirestore.settings({ timestampsInSnapshots: true })

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <AbilityContext.Provider value={ability}>
        <ThemeContext>
          <LazyApp />
          <ToastContainer
            newestOnTop={false}
            position='top-right'
            autoClose={2000}
            hideProgressBar={true}
            pauseOnFocusLoss={false}
            pauseOnHover={true}
            icon={true}
          />
          <SocketIO />
        </ThemeContext>
      </AbilityContext.Provider>
    </Suspense>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
