// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import todo from '@src/views/apps/todo/store'
import users from '@src/views/apps/user/store'
import email from '@src/views/apps/email/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import employees from '@src/hifive/employees/store'
import products from '@src/hifive/products/store'
import rooms from '@src/hifive/rooms/store'
import packages from '@src/hifive/packages/store'
import coupon from '@src/hifive/coupon/store'
import branch from '@src/hifive/branch/store'
import parents from '@src/hifive/parents/store'
import supervisor from '@src/hifive/supervisor/store'
import sales from '@src/hifive/sales/store'
import permissions from '@src/hifive/priviledges/store'
import subscription from '@src/hifive/subscription/store'
import kids from '@src/hifive/kids/store'
import promotion from '@src/hifive/promotion/store'
import invoice from '@src/hifive/invoice/store'
import admin from '@src/hifive/admin/store'
import profile from '@src/hifive/profile/store'
import chat from '@src/hifive/chat/store'

const rootReducer = {
  auth,
  todo,
  email,
  users,
  navbar,
  layout,
  calendar,
  ecommerce,
  dataTables,
  permissions,
  employees,
  products,
  rooms,
  packages,
  coupon,
  branch,
  parents,
  supervisor,
  sales,
  subscription,
  kids,
  promotion,
  invoice,
  admin,
  profile,
  chat
}

export default rootReducer
