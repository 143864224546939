// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('kids/getData', async (params, { getState }) => {
  const branch_id = getState().kids?.selectedBranch?.id || 0
  const response = await axios.post('/kids/checkedinlist', { ...params, branch_id })
  return response
})

export const getBranch = createAsyncThunk('kids/branch/list', async () => {
  const response = await axios.post('/branch/list')
  return response
})

export const kidCheckOut = createAsyncThunk('kids/kidCheckOut', async (data, { dispatch }) => {
  const response = await axios.post('/admin/actions/checkout', { id: data.id, kid_id: data.kid_id })
  await dispatch(getData({}))
  return response
})

export const getKidsSessionTime = createAsyncThunk('kids/getKidsSessionTime', async ({ kid_id, session_id }) => {
  const response = await axios.get(`/kids/session/time/${kid_id}?session_id=${session_id}`)
  return response
})

export const verifyOverTimePayment = createAsyncThunk('kids/verifyOverTimePayment', async ({ kid_id, q }) => {
  const response = await axios.get(`/kids/session/payments/${kid_id}${q}`)
  return response
})

export const sendOTP = createAsyncThunk('kids/sendOTP', async (data) => {
  const response = await axios.post('/otp/send', data)
  return response
})

export const verifyOTP = createAsyncThunk('kids/verifyOTP', async (data) => {
  const response = await axios.post('/otp/verify', data)
  return response
})

export const datatablesSlice = createSlice({
  name: 'kids',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    message: null,
    loading: 'idle',
    BRANCH: [],
    tableData: {
      allData: [],
      data: [],
      totalPages: 0,
      params: {}
    },
    selectedBranch: { id: 0, name_en: "Select a branch", name_ar: "اختر الفرع" },
    loadingId: null
  },
  reducers: {
    clearMessage: (state) => {
      state.message = null
    },
    setBranch: (state, action) => {
      state.selectedBranch = action.payload
    },
    setLoadingId: (state, action) => {
      state.loadingId = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getBranch.fulfilled, (state, action) => {
        state.BRANCH = action?.payload?.data?.data || []
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.tableData = action?.payload?.data?.data || {}
        state.loadingId = null
      })
      .addMatcher(
        action => action.type.endsWith("/pending"),
        state => { state.loading = "pending" }
      )
      .addMatcher(
        action => action.type.endsWith("/rejected"),
        state => { state.loading = "idle" }
      )
      .addMatcher(
        action => action.type.endsWith("/fulfilled"),
        state => { state.loading = "idle" }
      )
  }
})

export const actions = datatablesSlice.actions

export default datatablesSlice.reducer
