// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllPriviledges = createAsyncThunk('permissions/getAllPriviledges', async () => {
  const response = await axios.post('/priviledge/role/list', { page: 1, perPage: 20 })
  return response
})

export const getAllFeatures = createAsyncThunk('permissions/getAllFeatures', async () => {
  const response = await axios.post('/feature/list', { page: 1, perPage: 20 })
  return response
})

export const createRole = createAsyncThunk('permissions/createRole', async (data, { dispatch }) => {
  const response = await axios.post('/priviledge/role/create', data)
  await dispatch(getAllPriviledges())
  return response
})

export const updateRoles = createAsyncThunk('permissions/updateRoles', async (data, { dispatch }) => {
  const response = await axios.post('/priviledge/role/update', data)
  await dispatch(getAllPriviledges())
  return response
})

export const getRole = createAsyncThunk('permissions/getRole', async (data) => {
  const response = await axios.post('/priviledge/role/features', data)
  return response
})

export const getData = createAsyncThunk('permissions/getData', async (data) => {
  const response = await axios.post('/priviledge/role/users', data)
  return response
})

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selected: null,
    rolesArr: [],
    priviledges: [],
    tableData: {
      allData: [],
      data: [],
      totalPages: 0,
      params: {}
    }
  },
  reducers: {
    selectPermission: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAllFeatures.fulfilled, (state, action) => {
        state.rolesArr = action?.payload?.data?.data || []
      })
      .addCase(createRole.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
          state.currentRequestId = action.meta.requestId
        }
      })
      .addCase(createRole.fulfilled, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.currentRequestId = undefined
          state.message = action.payload.data.message
        }
      })
      .addCase(createRole.rejected, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.currentRequestId = undefined
        }
      })
      .addCase(getAllPriviledges.fulfilled, (state, action) => {
        state.priviledges = action?.payload?.data?.data || []
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.tableData = action?.payload?.data?.data || {}
      })
  }
})

export const { selectPermission } = permissionsSlice.actions

export default permissionsSlice.reducer
