// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import strings from '../../../configs/strings'
import { fileHandler } from '../../files'

export const downloadFiles = createAsyncThunk('products/downloadFiles', async (data) => {
  const files = {}
  const downloads = data
    .map(async ({ id, image }) => {
      files[`P${id}`] = image === '/product.png' ? image : await fileHandler.downloadFiles({ fileName: image, filePath: 'media/product/' })
    })
  await Promise.all(downloads)
  return files
})

export const getData = createAsyncThunk('products/getData', async (params, { dispatch }) => {
  const response = await axios.post('/product/pagelist', params)
  dispatch(downloadFiles(response?.data?.data?.allData || []))
  return response
})

export const createRecord = createAsyncThunk('products/create', async (data, { dispatch }) => {
  const payload = {
    ...data,
    tax_id: data?.tax_id?.id,
    included: data?.included?.id,
    branch_id: data?.branch_id?.id
  }
  const response = await axios.post('/product/create', payload)
  await dispatch(getData())
  return response
})

export const getBranch = createAsyncThunk('products/branch/list', async () => {
  const response = await axios.post('/branch/list')
  return response
})

export const getTaxes = createAsyncThunk('products/tax/list', async () => {
  const response = await axios.post('/tax/list')
  return response
})

export const deleteRow = createAsyncThunk('products/deleteRow', async ({ id }, { dispatch }) => {
  const response = await axios.post('/product/delete', { id })
  await dispatch(getData())
  return response
})

export const editRow = createAsyncThunk('products/editRow', async (data, { dispatch }) => {
  const payload = {
    ...data,
    tax_id: data?.tax_id?.id,
    included: data?.included?.id,
    branch_id: data?.branch_id?.id
  }
  const response = await axios.post('/product/update', payload)
  await dispatch(getData())
  return response
})

export const addStock = createAsyncThunk('products/addStock', async (data, { dispatch }) => {
  const response = await axios.post('/product/addstock', data)
  await dispatch(getData())
  return response
})

export const datatablesSlice = createSlice({
  name: 'products',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    message: null,
    loading: 'idle',
    BRANCH: [],
    TAX: [],
    TAX_TYPE: [
      { id: 1, name_en: 'Included', name_ar: "Included" },
      { id: 2, name_en: 'Excluded', name_ar: "Excluded" }
    ],
    PRODUCTS: [],
    tableData: {
      allData: [],
      data: [],
      totalPages: 0,
      params: {}
    },
    files: {}
  },
  reducers: {
    clearMessage: (state) => {
      state.message = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(createRecord.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
          state.currentRequestId = action.meta.requestId
        }
      })
      .addCase(createRecord.fulfilled, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.currentRequestId = undefined
          state.message = action?.payload?.data?.message
        }
      })
      .addCase(createRecord.rejected, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.message = action?.payload?.data?.message || strings.SOMETHING_WENT_WRONG_REDUCER
          state.currentRequestId = undefined
        }
      })
      .addCase(deleteRow.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
          state.currentRequestId = action.meta.requestId
        }
      })
      .addCase(deleteRow.fulfilled, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.currentRequestId = undefined
          state.message = action?.payload?.data?.message
        }
      })
      .addCase(deleteRow.rejected, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.message = action?.payload?.data?.message || strings.SOMETHING_WENT_WRONG_REDUCER
          state.currentRequestId = undefined
        }
      })
      .addCase(editRow.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
          state.currentRequestId = action.meta.requestId
        }
      })
      .addCase(editRow.fulfilled, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.currentRequestId = undefined
          state.message = action?.payload?.data?.message
        }
      })
      .addCase(editRow.rejected, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.message = action?.payload?.data?.message || strings.SOMETHING_WENT_WRONG_REDUCER
          state.currentRequestId = undefined
        }
      })
      .addCase(getBranch.fulfilled, (state, action) => {
        state.BRANCH = action?.payload?.data?.data || []
      })
      .addCase(getTaxes.fulfilled, (state, action) => {
        state.TAX = action?.payload?.data?.data || []
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.tableData = action?.payload?.data?.data || {}
      })
      .addCase(downloadFiles.fulfilled, (state, action) => {
        state.files = action?.payload || {}
      })
  }
})

export const actions = datatablesSlice.actions

export default datatablesSlice.reducer
