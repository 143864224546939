import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { fileHandler } from '../../files'
import { randomID } from '../../files/randomID'

const getPagination = (page, perPage) => {
  const limit = perPage ? +perPage : 10
  const offset = page ? (page - 1) * limit : 0
  return { limit, offset }
}

export const getData = createAsyncThunk('promotion/getData', async ({ page, perPage, q }, { getState }) => {
  const data = getState().promotion.tableData.allData
  const { limit, offset } = getPagination(page, perPage)
  const allData = data.slice(offset, limit)
  const filter = q ? allData.filter(r => JSON.stringify(r).toLowerCase().indexOf(q.toLowerCase()) >= 0) : null
  const response = {
    data: filter || allData,
    allData,
    totalPages
  }

  return {
    data: {
      data: response,
      status: true
    }
  }
})

export const sendSMS = createAsyncThunk('promotion/sendSMS', async params => {
  const response = await axios.post('/promotion/sms', params)
  return response
})

export const sendBulkSMS = createAsyncThunk('promotion/sendBulkSMS', async (_, { getState }) => {
  const data = getState().promotion.tableData.allData
  const file = getState().promotion.file
  const response = await axios.post('/promotion/bulksms', data)
  fileHandler.uploadFiles({
    filePath: 'promotion/excel',
    fileName: randomID(file[0]),
    files: file
  })
  return response
})

export const reduxSlice = createSlice({
  name: 'promotion',
  initialState: {
    tableData: {
      allData: [],
      data: [],
      totalPages: 0,
      params: {}
    },
    loading: 'idle',
    message: null,
    file: null
  },
  reducers: {
    clearMessage: (state) => {
      state.message = null
    },
    setImportedData: (state, action) => {
      state.file = action.payload.file
      state.tableData = {
        allData: action.payload.data,
        data: action.payload.data,
        totalPages: action.payload.data.length / 10,
        params: {
          page: 1,
          perPage: 10,
          q: null
        }
      }
    },
    onDelete: (state, action) => {
      state.tableData.allData = state.tableData.allData.filter(({ id }) => id !== action.payload)
      state.tableData.data = state.tableData.data.filter(({ id }) => id !== action.payload)
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.tableData = action?.payload?.data?.data || {}
      })
      .addCase(sendSMS.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
          state.currentRequestId = action.meta.requestId
        }
      })
      .addCase(sendSMS.fulfilled, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.currentRequestId = undefined
          state.message = action?.payload?.data?.message
        }
      })
      .addCase(sendSMS.rejected, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.message = action?.payload?.data?.message
          state.currentRequestId = undefined
        }
      })
      .addCase(sendBulkSMS.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
          state.currentRequestId = action.meta.requestId
        }
      })
      .addCase(sendBulkSMS.fulfilled, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.currentRequestId = undefined
          state.message = action?.payload?.data?.message
          state.tableData = {
            allData: [],
            data: [],
            totalPages: 0,
            params: {}
          }
        }
      })
      .addCase(sendBulkSMS.rejected, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.message = action?.payload?.data?.message
          state.currentRequestId = undefined
        }
      })
  }
})

export const actions = reduxSlice.actions

export default reduxSlice.reducer
