import React, { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import socket from './socket'
import { actions } from './store'
// import addNotification from 'react-push-notification'

const userProfile = JSON.parse(localStorage.getItem('userData'))

const SocketIO = () => {
    const dispatch = useDispatch()
    const onConnection = useCallback(() => {
        const { connected, id, io } = socket
        const x = {
            id,
            connected,
            currentTransport: connected ? io.engine.transport.name : '-'
        }
        console.log(x)
    }, [])

    const onMessage = useCallback((content) => {
        // console.log('onMessage', content)

        const chatPage = window.location.pathname === '/apps/chat'

        if (!!content) {

            if (!chatPage) {
                const msgCount = localStorage.getItem('msgCount') || 0
                const newCount = 1 + parseInt(msgCount)
                localStorage.setItem('msgCount', newCount)
                dispatch(actions.updateMsgCount(newCount))
            }

            if (parseInt(userProfile.id) === parseInt(content.data.receiverId)) {
                dispatch(actions.updateMessage(content.data))
            }

            //update delivery
            socket.emit('message_received', {
                id: content.data.id,
                receiverId: content.data.receiverId,
                socketId: content.id
            })

            // handleNotification(content)
        }
    }, [])

    // const handleNotification = (message) => {
    //     addNotification({
    //         title: 'Notification',
    //         subtitle: '',
    //         message,
    //         theme: 'darkblue',
    //         native: true // when using native, your OS will handle theming.
    //     })
    // }

    const onChatData = (data) => {
        dispatch(actions.updateChatData(data))
    }

    const onAllChatData = (data) => {
        dispatch(actions.chatData(data))
    }
    const newMessageResponse = ({ data, msg }) => {
        // console.log(data)
        const { id } = JSON.parse(msg)
        dispatch(actions.newMessageResponse({ data, id }))
    }

    const onMessageDelivered = (id) => {
        dispatch(actions.onMessageDelivered(id))
    }

    useEffect(() => {
        socket.on('connect', () => onConnection())

        socket.on('message', (content) => onMessage(content))
        socket.on('chat_data', (content) => onChatData(content))
        socket.on('get_all_chat', (content) => onAllChatData(content))
        socket.on('new_chat_response', (content) => newMessageResponse(content))
        socket.on('message_delivered', (content) => onMessageDelivered(content))

        return () => {
            socket.off('connect')
            socket.off('disconnect')
            socket.off('message')
        }
    }, [onConnection, onMessage])

    return (
        <React.Fragment></React.Fragment>
    )
}

export default SocketIO