// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('supervisor/getData', async params => {
  const response = await axios.post('/room/supervisor', params)
  return response
})

export const sendOTP = createAsyncThunk('supervisor/sendOTP', async () => {
  const response = await axios.post('/otp/supervisor/send')
  return response
})

export const verifyOTP = createAsyncThunk('supervisor/verifyOTP', async (otp) => {
  const response = await axios.post('/otp/supervisor/verify', otp)
  return response
})

export const kidCheckIn = createAsyncThunk('supervisor/kidCheckIn', async ({
  id,
  name_en,
  name_ar,
  parent_id,
  kid_sessions }, { dispatch, getState }) => {
  const { userInfo, roomsList } = getState().supervisor
  const userRoom = roomsList.filter(({ id }) => id === userInfo.room_id)[0]
  const response = await axios.post('/supervisor/checkin',
    {
      kid_id: id,
      name_en,
      name_ar,
      room_id: userInfo.room_id,
      parent_id,
      badge: kid_sessions?.[0]?.badge,
      room_en: userRoom.name_en,
      room_ar: userRoom.name_ar
    })
  await dispatch(getData())
  return response
})

export const kidCheckOut = createAsyncThunk('supervisor/kidCheckOut', async ({ id }, { getState, dispatch }) => {
  const response = await axios.post('/supervisor/checkout',
    {
      kid_id: id,
      room_id: getState().supervisor.userInfo.room_id
    })
  await dispatch(getData())
  return response
})

export const roomLogout = createAsyncThunk('supervisor/roomLogout', async () => {
  const response = await axios.post('/room/supervisor/logout')
  return response
})

export const roomLogin = createAsyncThunk('supervisor/roomLogin', async (data, { dispatch }) => {
  const response = await axios.post('/room/supervisor/login', data)
  await dispatch(getData())
  return response
})

export const roomsList = createAsyncThunk('supervisor/roomsList', async () => {
  const response = await axios.post('/room/supervisor/roomlist')
  return response
})


export const supervisorSlice = createSlice({
  name: 'supervisor',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    ROOM_DATA: [],
    userInfo: {},
    loadingId: null,
    tableData: {
      allData: [],
      data: [],
      totalPages: 0,
      params: {}
    },
    roomsList: [],
    roomInfo: {}
  },
  reducers: {
    clearMessage: (state) => {
      state.message = null
    },
    setLoadingId: (state, action) => {
      state.loadingId = action.payload
    },
    setRoomInfo: (state, action) => {
      state.roomInfo = action.payload
    },
    updateKidMessage: (state, action) => {
      const { kid_id, msg } = action.payload
      state.tableData.data = [...state.tableData.data].map(k => ({ ...k, msg: k.id === kid_id ? msg : null }))
      state.tableData.allData = state.tableData.allData.map(k => ({ ...k, msg: k.id === kid_id ? msg : null }))
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.tableData = action?.payload?.data?.data || {}
        state.userInfo = action.payload.data.params
        state.loadingId = null
      })
      .addCase(roomLogout.fulfilled, (state) => {
        state.tableData = {}
        state.userInfo = {}
      })
      .addCase(roomsList.fulfilled, (state, action) => {
        state.roomsList = action?.payload?.data?.data || []
      })
  }
})

export const actions = supervisorSlice.actions

export default supervisorSlice.reducer
