import axios from 'axios'
import { toast } from 'react-toastify'
import { toastMessage } from '../../../utility/Utils'
import jwtDefaultConfig from './jwtDefaultConfig'

let postRequests = []
export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // ** For Refreshing Token
  subscribers = []

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // ** Request Interceptor
    axios.interceptors.request.use(
      config => {
        //pass base URL
        axios.defaults.baseURL = process.env.REACT_APP_BACKEND_BASE_URL
        if (config.method.toUpperCase() === "POST") {
          const find = postRequests.find(({ url, data, timestamp }) => url === config.url && JSON.stringify(data) === JSON.stringify(config.data) && (new Date().getTime() - timestamp) < 2000)
          if (find) {
            return Promise.reject({ data: { message: "DUPES" } })
          } else {
            postRequests = postRequests.filter(({ url }) => url !== config.url)
            postRequests.push({
              url: config.url,
              data: config.data,
              timestamp: new Date().getTime()
            })
          }
        }

        // ** Get token from localStorage
        const accessToken = this.getToken()

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          // ** eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${JSON.parse(accessToken)}`
          config.headers.supervisor = `${this.jwtConfig.tokenType} ${localStorage.getItem("supervisorToken")}`
        }
        return config
      },
      error => Promise.reject(error)
    )

    // ** Add request/response interceptor
    axios.interceptors.response.use(
      response => response,
      error => {
        // ** const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        if (!response?.data?.status) {
          if (response?.data?.supervisor) {
            localStorage.removeItem('supervisorToken')
          } else {
            toast.error(toastMessage(response.data.message))
          }
          // console.log(response)
          // if (response?.data?.supervisor) {
          //   // localStorage.removeItem('supervisorToken')
          //   // window.location.href = "/verify-supervisor"
          // }

          if (response?.data?.logout) {
            localStorage.removeItem('userData')
            localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
            localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
            localStorage.removeItem('supervisorToken')
            window.location.href = "/login"
          }
        }

        // ** if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // ** Update accessToken in localStorage
              this.setToken(r.data.accessToken)
              this.setRefreshToken(r.data.refreshToken)

              this.onAccessTokenFetched(r.data.accessToken)
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // ** Make sure to assign accessToken according to your response.
              // ** Check: https://pixinvent.ticksy.com/ticket/2413870
              // ** Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              originalRequest.headers.supervisor = `${this.jwtConfig.tokenType} ${localStorage.getItem("supervisorToken")}`
              resolve(this.axios(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      }
    )
  }

  backendApiCall(url, payload, method = "post") {
    const params = {
      data: payload,
      method,
      url
    }
    return axios(params)
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.backendApiCall('/user/login', ...args)
    // return axios.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return axios.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken()
    })
  }
}
