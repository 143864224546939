import io from 'socket.io-client'

const userData = localStorage.getItem("userData") || undefined
const token = userData ? JSON.parse(userData).accessToken : undefined
const socketURL = process.env.REACT_APP_SOCKET_BASE_URL

const socket = io(socketURL, {
    extraHeaders: {
        auth: token
    },
    auth: { token }
})

export default socket