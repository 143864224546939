import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

const firebase = initializeApp({
    apiKey: "AIzaSyCYVT_11hlGNj91vqDOijwDa1GivnNB_dg",
    authDomain: "hi-5-a0639.firebaseapp.com",
    projectId: "hi-5-a0639",
    storageBucket: "hi-5-a0639.appspot.com",
    messagingSenderId: "761977874124",
    appId: "1:761977874124:web:b93d12714c6223761d0469",
    measurementId: "G-FP58KZ5C3L",
    databaseURL: "hi-5-a0639-default-rtdb.firebaseio.com"
})

const firebaseDB = getFirestore(firebase)
export { firebaseDB }
// ({ timestampsInSnapshots: true })

export default firebase