// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import strings from '../../../configs/strings'

export const getData = createAsyncThunk('branch/getData', async params => {
  const response = await axios.post('/branch/pagelist', params)
  return response
})

export const createRecord = createAsyncThunk('branch/create', async (data, { dispatch }) => {
  const response = await axios.post('/branch/create', data)
  await dispatch(getData())
  return response
  // return { allData: response.data.allData, data: response.data.invoices, totalPages: response.data.total, params }
})

export const editRow = createAsyncThunk('branch/editRow', async (data, { dispatch }) => {
  const response = await axios.post('/branch/update', data)
  await dispatch(getData())
  return response
})

export const deleteRow = createAsyncThunk('branch/deleteRow', async (id, { dispatch }) => {
  const response = await axios.post('/branch/delete', id)
  await dispatch(getData())
  return response
})

export const datatablesSlice = createSlice({
  name: 'branch',
  initialState: {
    data: [],
    total: 1,
    params: {
      page: 1,
      size: 10
    },
    allData: [],
    message: null,
    loading: 'idle',
    TAX: [],
    tableData: {
      allData: [],
      data: [],
      totalPages: 0,
      params: {}
    }
  },
  reducers: {
    clearMessage: (state) => {
      state.message = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(createRecord.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
          state.currentRequestId = action?.meta?.requestId
        }
      })
      .addCase(createRecord.fulfilled, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action?.meta?.requestId
        ) {
          state.loading = 'idle'
          state.currentRequestId = undefined
          state.message = action?.payload?.data?.message
        }
      })
      .addCase(createRecord.rejected, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action?.meta?.requestId
        ) {
          state.loading = 'idle'
          state.message = action?.payload?.data?.message || strings.SOMETHING_WENT_WRONG_REDUCER
          state.currentRequestId = undefined
        }
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.tableData = action?.payload?.data?.data || {}
      })
      .addCase(editRow.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending'
          state.currentRequestId = action.meta.requestId
        }
      })
      .addCase(editRow.fulfilled, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.currentRequestId = undefined
          state.message = action?.payload?.data?.message
        }
      })
      .addCase(editRow.rejected, (state, action) => {
        if (
          state.loading === 'pending' &&
          state.currentRequestId === action.meta.requestId
        ) {
          state.loading = 'idle'
          state.message = action?.payload?.data?.message || strings.SOMETHING_WENT_WRONG_REDUCER
          state.currentRequestId = undefined
        }
      })
  }
})

export const actions = datatablesSlice.actions

export default datatablesSlice.reducer
