export const randomID = (file, length = 10) => {
    if (file instanceof Object === false || !file.name) {
        return undefined
    }

    const { name } = file
    const ext = name.split(".").slice(-1)[0]
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    result = `${name.replace(ext, "")}${result}.${ext}`
    return result
}